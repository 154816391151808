import { graphql } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Resource } from "../classes/Resource";
import TechResContent from "../components/ui/TechnicalResources/Content/TechResContent";
// import get from "lodash/get";
import { colors } from "../vars/palette";
import styled from "styled-components";
import { BREAKPOINT_MEDIUM_MAX } from "../components/ui/Typography";
import FixedNav from "../components/ui/TechnicalResources/Nav/FIxedNav";
import TechButtons from "../components/ui/TechnicalResources/Buttons/TechButtons";
import CalcThrustRestraint from "../components/calculators/kind/Thrust";
import CalcSupports from "../components/calculators/kind/Supports";
import CalcHydraulic from "../components/calculators/kind/Hydraulic";
import CalcThicknessDesign from "../components/calculators/kind/Thickness";
import CalcLcca from "../components/calculators/kind/LCCA/LCCA";
import TechResNav from "../components/ui/TechnicalResources/Nav/TechResNav";
import DipraBreadcrumbs from "../components/Layout/Breadcrumbs";
import { WmkSeo } from "wmk-seo";
import { ResourceQuery } from "../fragments/NodeResourceFields";
import { RolloverMenuFields } from "../fragments/NodeMenuFields";

const NullComponent = () => <></>;

const StyledContainer = styled(Container)`
  & > .row {
    min-height: 100vh;
  }
  .nav-container {
    @media print {
      display: none;
    }
    background-color: ${colors.techNavGreen.hex};
    padding: 14vh 3rem;
    a {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    .nav-container {
      padding: 3vh 1.5rem;
      height: fit-content;
    }
  }
`;

const registeredCalculators = {
  "thrust-restraint-calculator": CalcThrustRestraint,
  "design-on-supports": CalcSupports,
  "hydraulic-analysis-and-greenhouse-gas-emissions": CalcHydraulic,
  "thickness-design": CalcThicknessDesign,
  "life-cycle-cost-analysis": CalcLcca
};

/**
 *
 * @param {Object} pageContext
 * @param {Object} data
 * @returns {JSX}
 */
const ResourceNode = ({
  pageContext,
  data
}: {
  pageContext: { parentPath: string; slug: string };
  data: ResourceNodeQuery;
}) => {
  const resource = new Resource(data.resource);
  const techMenus = data.techMenus.edges.map((e) => e.node);
  let menuParentSlug;
  const thisSlug = pageContext?.slug;
  const menu = techMenus.reduce((match, m) => {
    const parentSlug = m.menuParent?.resourceSlug;
    const nearest = resource.hierarchy.nearest().slug;

    if (parentSlug === thisSlug) {
      menuParentSlug = parentSlug;
      const childLinks = m.links.length ? m.links : [];
      return [...childLinks];
    } else {
      if (parentSlug === nearest) {
        menuParentSlug = parentSlug;
        const childLinks = m.links.length ? m.links : [];
        return [...childLinks];
      }
    }
    return match;
  }, []);
  const parent = resource?.parent;
  const path = resource?.parentPath;
  const slug = resource?.slug;
  const isCalc = parent === `/calculators`;
  const CalcJsx = isCalc
    ? registeredCalculators[`${slug}`]
    : NullComponent;
  return (
    <>
      <WmkSeo.Meta
        title={resource.serpTitle ? resource.serpTitle : resource.title}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug={resource?.parentPath === "/technical-resources/" ? slug : `/${slug}`}
        path={resource?.parentPath ? resource.parentPath : ""}
        description={resource.meta}
        ogImageUrl={resource.ogImage ? resource.ogImage : ""}
        twitterImageUrl={resource.twitterImage ? resource.twitterImage : ""}
        twitterHandle={data.site.siteMetadata.twitter}
      />
      <StyledContainer fluid>
        <Row style={{}}>
          <Col className="nav-container" xs={12} sm={12} md={5} lg={4}>
            <TechResNav
              resource={resource ? resource : undefined}
              menu={menu}
              slug={pageContext.slug}
            />
          </Col>
          <Col xs={12} sm={12} md={7} lg={8}>
            {/* content-target div used to snap page down to the content when a resource nav item is clicked on mobile */}
            <div id="content-target" />
            <DipraBreadcrumbs route={`${path}/${slug}`} />
            {isCalc ? <CalcJsx /> : <TechResContent resource={resource} />}
          </Col>
        </Row>
      </StyledContainer>
      <FixedNav parentSlug={menuParentSlug ? menuParentSlug : thisSlug}>
        <TechButtons menu={menu} slug={pageContext.slug} />
      </FixedNav>
    </>
  );
};

export default ResourceNode;

export interface ResourceNodeQuery {
  resource: ResourceQuery;
  techMenus: {
    edges: {
      node: RolloverMenuFields;
    }[];
  };
  site: {
    siteMetadata: {
      description: string;
      baseUrl: string;
      title: string;
      twitter: string;
    };
  };
}

export const query = graphql`
  query resQuery($slug: String) {
    resource: contentfulResource(slug: { eq: $slug }) {
      ...NodeResourceFields
      ...ResourceBlockIconCopy
      ...ResourceBlockImageCopy
      ...ResourceAsset
    }
    techMenus: allContentfulMenu(
      filter: { title: { regex: "/technical resources >/i" } }
    ) {
      edges {
        node {
          ...NodeMenuFields
        }
      }
    }
    site {
      siteMetadata {
        baseUrl
        title
        twitter
      }
    }
  }
`;
